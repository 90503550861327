import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import Backlink from "components/legal-backlink"

const StyledLegalPrivacy = styled.section`
  padding: 60px 0;
  border-bottom-width: 0;

  h1 {
    font-size: 32px;
    text-align: center;
    margin: 0 auto 2rem;
    
      ${breakpoint.medium`
        font-size: 42px;
      `}
  }
  
  b {
		font-weight: 600;
  }

	h2 {
		font-weight: 600;
    font-size: 17px;
    ${breakpoint.medium`
      font-size: 21px;
    `}
  }
	
  .content {
    width: 100%;
    display: block;

  ${breakpoint.small`
	column-count: 2;
	column-gap: 2em;
  `}

    &:last-child {
      margin-bottom: 0;
    }

    p {
      width: 100%;
      margin-bottom: 20px;
    }
    
    ul {
      list-style: outside;
      margin-bottom: 20px;
      padding-left: 20px;
	  }
	
    li {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    a {
      color: ${colors.orange};

      &:hover,
      &.active {
        color: ${colors.purpleDark};
      }
    }

    button {
      max-width: 320px;
      background-color: ${colors.orange};
      color: ${colors.charcoal};

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
  }
`

class Section extends React.Component {
  render = props => (
    <StyledLegalPrivacy>
      <Container>
        <h1>{this.props.title}</h1>
        {this.props.children}
      </Container>
    </StyledLegalPrivacy>
  )
}

class LegalPrivacy extends React.Component {

  render = () => (
    <React.Fragment>
      <Backlink pagetitle="Privacy Policy" />
      <Section title="Privacy Policy">
        <div className="content visible">
          <p>Visby Medical, Inc. (“we”, “us” or “our”) is committed to protecting your privacy. We have created this
            Privacy Policy to inform you of our policies regarding the collection, use and disclosure of personal
            information and the choices you have associated with that information.</p>
          <p>This Privacy Policy applies to all personal information collected or processed online or offline through:
            the website located at <Link to="/">www.visbymedical.com</Link>, and
            all corresponding webpages and websites that link to this “Privacy Policy (the “Site”); any Visby mobile
            application; our diagnostic devices; and laboratory analyses (collectively, the “Services”).</p>
          <p>Before using our Services, please carefully read our <Link to="/legal/terms/">website Terms of
            Use</Link> and this Privacy Policy. By using this Site, you consent to the collection and use of your
            personal information in accordance with this Privacy Policy and our Terms of Use, and any policies or
            agreements incorporated therein. If you do not agree with any part of this Policy or our Terms of Use, you
            should not use or access our Services. </p>

          <h2>1. INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</h2>
          <p>We collect personal information when you use our Services. Personal information is any information that may
            be used to identify an individual, identifies you personally or could be used to identify you. </p>
          <p>The types of personal information that we may collect about you include, but are not limited to: the
            information you provide to us, information collected automatically about your use of our services,
            information collected through our diagnostic devices, and information from third parties, including our
            business partners. </p>

          <p><b>Information You Voluntarily Provide to Us.</b> The personal information we collect on or through our
            Services may include:</p>
          <ul>
            <li>Information that you provide by filling in forms on our Site. This includes information provided at the
              time of registering to use our Site, subscribing to or purchasing our services, posting material or
              requesting further services. We may also ask you for information when you report a problem with our Site.
            </li>
            <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
            <li>Your responses to surveys that we might ask you to complete for marketing or research purposes.</li>
            <li>Details of transactions you carry out through our Site and of the fulfillment of your orders. You may be
              required to provide financial information, such as payment card information before placing an order
              through our Site.
            </li>
            <li>The name and email address of individuals to whom you wish to refer our Services.</li>
            <li>Your search queries on the Site.</li>
          </ul>

          <p>You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of
            the Site, or transmitted to other users of the Site or third parties (collectively, “User Content”). Your
            User Content is posted on and transmitted to others at your own risk. We cannot control the actions of other
            users of the Services with whom you may choose to share your User Content. Therefore, we cannot and do not
            guarantee that your User Content will not be re-posted or viewed by unauthorized persons. </p>
          <p><b>Information Collected Automatically.</b> As you navigate through and interact with our Services, we may
            use automatic data collection technologies to collect certain information about your equipment, browsing
            actions and patterns, including:</p>
          <ul>
            <li>Details of your visits to our Site, including, but not limited to, traffic data, location data, logs and
              other communication data and the resources that you access and use on the Site.
            </li>
            <li>Information about your computer and internet connection, including your IP address, operating system and
              browser type.
            </li>
            <li>Information about your preferences to make your use of the Site more productive, via the use of Cookies.
              For more information on Cookies, please see Section 2.
            </li>
          </ul>
          <p><b>Information Collected in Connection to Our Diagnostic Devices.</b> If you purchase or receive access to
            a Visby diagnostic device, we will process the following information:</p>
          <ul>
            <li>Our diagnostic device will be used in an appropriately licensed laboratory, where the device will
              collect your biospecimen (usually your nasal swab sample) in order to process and provide the diagnostic
              results back to you.
            </li>
            <li>Once an appropriately credentialed laboratory receives a used diagnostic device, we may, for regulatory,
              quality, product development, or other purposes, further process any additional biospecimen remaining in
              the device. The biospecimen information is de-identified when it arrives at our laboratory, meaning it is
              not connected to your name or contact information, and identified only by a randomly generated number.
              Your biospecimen is securely destroyed after the laboratory completes its work, subject to laboratory
              legal and regulatory requirements.
            </li>
          </ul>
          <p><b>Information From Third Parties.</b> When you interact with any Visby account on a social media platform,
            we may collect the personal information that you or the platform make available to us on that page or
            account, including your social media account ID and/or user name associated with that social media service,
            your profile picture, email address, friends list or information about the people and groups you are
            connected to and how you interact with them, and any information you have made public in connection with
            that social media service. The information we obtain depends on your privacy settings on the applicable
            social media service; we will comply with the privacy policies of the social media platform and we will only
            collect and store such personal information that we are permitted to collect by those social media
            platforms. </p>

          <h2>2. COOKIES AND OTHER TRACKING TECHNOLOGY</h2>
          <p>Cookies are text files, containing small amounts of information, which are downloaded to your browsing
            device (such as a computer, mobile device or smartphone) when you visit or use our website or Services.
            Cookies can be recognized by the website that downloaded them — or other websites that use the same cookies.
            This helps websites know if the browsing device has visited them before. </p>
          <p>There are session cookies and persistent cookies. A session cookie lasts while your browser is open and is
            automatically deleted when you close your browser. A persistent cookie lasts until you or your browser
            deletes the cookies or they expire. </p>
          <p>Cookies set by us are called “first party cookies”, while cookies set by parties other than Visby are
            called “third party cookies”. The parties that set third party cookies can recognize your device, both when
            you use the Services and when you use other websites or mobile apps. </p>
          <p>Cookies collect and process both personal information, as well as information that does not identify you as
            an individual. To the extent that IP addresses or similar identifiers are considered personal information by
            local law, we will treat these identifiers as prescribed under such applicable laws. If we combine
            non-personal information with personal information, the combined information will be treated as personal
            information for as long as it remains combined. </p>
          <p>In addition to cookies, we may use other technologies that are similar to cookies, like web beacons, flash
            cookies, or pixels to track how you use our Services. </p>
          <ul>
            <li><b>Web Beacons:</b> Web beacons, or “clear gifs”, are tiny graphics with a unique identifier, similar in
              function to cookies. Web beacons are placed on a website or in an email that alone or in conjunction with
              cookies compile information about your usage of or your interaction with the Services. In contrast to
              cookies, which are stored on a user’s computer hard drive or device, clear gifs are embedded invisibly on
              web pages. Similar to cookies, beacons primarily help us better manage content on our Services by
              informing us which content is effective.
            </li>
            <li><b>Flash Cookies:</b> We may also use so-called “flash cookies” (also known as “Local Shared Objects” or
              “LSOs”) to collect and store information about your use of our Services. Flash cookies are commonly used
              for advertisements and videos.
            </li>
            <li><b>Pixels:</b> A “pixel” or “tag” can be placed on a website or within an email for the purposes of
              tracking your interactions with our websites or when emails are opened or accessed; pixels are often used
              in combination with cookies.
            </li>
          </ul>
          <p>The following types of cookies and tracking technologies are used on our Services:</p>
          <ul>
            <li><b>Strictly Necessary Cookies.</b> These cookies are essential because they enable you to use our
              Services. For example, strictly necessary cookies allow you to access secure areas on our Services. These
              cookies do not gather information about you for marketing purposes. This category of cookies is essential
              for our Services to work and they cannot be disabled.
            </li>
            <li><b>Functional or Preference Cookies.</b> We use functional cookies to remember your choices so we can
              tailor our Services to provide you with enhanced features and personalized content. For example, these
              cookies can be used to remember your name or preferences on our Services. We do not use functional cookies
              to target you with online marketing. While these cookies can be disabled, this may result in less
              functionality during your use of our Services.
            </li>
            <li><b>Performance or Analytic Cookies.</b> These cookies collect passive information about how you use our
              Services, including webpages you visit and links you click. We use the information collected by such
              cookies to improve and optimize our Services. We do not use these cookies to target you with online
              marketing. You can disable these cookies.
            </li>
            <li><b>Advertising or Targeting Cookies.</b> These cookies are used to make advertising messages more
              relevant to you. They perform functions like preventing the same ad from continuously reappearing,
              ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that
              are based on your interests. Our third-party advertising partners may use these cookies to build a profile
              of your interests and deliver relevant advertising on other sites. You may disable the use of these
              cookies as set forth below.
            </li>
          </ul>

          <p><b>Your Choices</b></p>
          <p>Your browser may provide you with the option to refuse some or all browser cookies. You may also be able to
            remove cookies from your browser. You can exercise your preferences in relation to cookies served on our
            Services by taking the steps outlined below:</p>
          <ul>
            <li><b>First-Party Cookies.</b> You can use the browser with which you are viewing this Site to enable,
              disable or delete cookies. To do this, follow the instructions provided by your browser (usually located
              within the “Help”, “Tools” or “Edit” settings). Please note, if you set your browser to disable cookies,
              you may not be able to access secure areas of the Site. Also, if you disable cookies other parts of the
              Services may not work properly. You can find more information about how to change your browser cookie
              settings at <a href="https://www.allaboutcookies.org/"
                             target="_blank" rel="noopener noreferrer">https://www.allaboutcookies.org/</a>.
            </li>
            <li><b>Third-Party Cookies.</b> To opt-out of third-party advertising networks and similar entities that use
              advertising cookies go to <a href="https://optout.aboutads.info/"
                                           target="_blank"
                                           rel="noopener noreferrer">https://optout.aboutads.info/</a>. Once you
              click the
              link you may choose to opt-out of such advertising from all participating advertising companies or only
              advertising provided by specific advertising entities. For more information about third-party advertising
              networks and similar entities that use these technologies, please see <a
                href="https://youradchoices.com/control" target="_blank"
                rel="noopener noreferrer">https://youradchoices.com/control</a>.
            </li>
          </ul>
          <p>We do not control third-parties’ collection or use of your information to serve interest-based advertising.
            However, these third-parties may provide you with ways to choose not to have your information collected or
            used in this way. In addition, most web browsers provide help pages relating to setting cookie preferences.
            More information may be found for the following browsers here:</p>
            <ul>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank"
                   rel="noopener noreferrer">Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/en-us/kb/260971" target="_blank" rel="noopener noreferrer">Internet
              Explorer</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                   target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
            <li><a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
                   target="_blank" rel="noopener noreferrer">Safari (Desktop)</a></li>
            <li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">Safari
              (Mobile)</a></li>
            <li><a href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067" target="_blank"
                   rel="noopener noreferrer">Android
              Browser</a></li>
            <li><a href="http://www.opera.com/help" target="_blank" rel="noopener noreferrer">Opera</a></li>
            <li><a href="http://www.opera.com/help/mobile/android#privacy" target="_blank" rel="noopener noreferrer">Opera
              Mobile</a></li>
          </ul>

          <p><b>Do Not Track</b></p>
          <p>Some Internet browsers, such as Internet Explorer, Firefox, and Safari, include the ability to transmit “Do
            Not Track” or “DNT” signals. Since uniform standards for “DNT" signals have not been adopted, our Sites do
            not currently process or respond to “DNT” signals.</p>

          <h2>3. HOW WE USE YOUR INFORMATION</h2>
          <p>We will only use your personal information as described in this Privacy Policy or as disclosed to you prior
            to such processing taking place. The purposes for which we may use your personal information include: </p>
          <p><b>To Provide Our Services.</b> When you access or use our Services, we process certain personal
            information about you to be able to provide you with our Services. Some examples of how we process your
            personal information in our Services include, but are not limited to:</p>
          <ul>
            <li>To coordinate and communicate about logistics and tracking of orders placed by appropriately licensed
              laboratory purchasers of our Services, and
            </li>
            <li>To pay for the Services</li>
          </ul>

          <p>We cannot provide you with our Services without such processing of your personal information.</p>
          <p><b>To Communicate with and Market to You.</b> If you sign up for our Services, you will receive
            Service-related communications. Our Service-related communications may include transactional messages about
            your account, billing information, your results, surveys, support, customer service, research you may be
            interested in or are participating in, policy changes, or other Service-related notifications. We may also
            use your personal information for marketing, promotional, or sweepstakes related communications and
            initiatives. You can manage your email preferences and opt out of certain communications. However,
            service-related communications are necessary for us to continue to provide our Services to you. </p>
          <p><b>To Enforce Our Terms, Agreements or Policies.</b> To maintain a safe, secure, and trusted environment
            for you when you use the Services, we use your personal information to make sure our terms, policies, and
            agreements with you and any third parties are enforced. We actively monitor, investigate, prevent, and
            mitigate any suspected or actual prohibited activities on our Services. We are required to process your
            personal information for this purpose to provide our Services.</p>
          <p><b>For Product Research and Development.</b> We want to ensure our Services are continually improving and
            expanding so that we meet and exceed your needs and expectations. To do so, we may process your personal
            information to improve, optimize, or expand our Services or features of our Services. We do so by processing
            information about your use of the Services, any information you provide to us, and by measuring, tracking,
            and analyzing trends and usage in connection to your use or the performance of our Services. We take
            additional security measures when processing your personal information for such purposes, such as by
            de-identifying (or “pseudonymizing”) your personal information and limiting access to such data. Without
            processing your information for such purposes, we may not be able to ensure your continued satisfaction when
            using our Services.</p>
          <p><b>For Scientific Research and Clinical Trial Support.</b> Visby may use your de-identified or
            pseudonymized personal information conduct, whether independently, in collaboration with third parties, or
            sponsored by a third party, scientific research aimed at the creation of generalizable knowledge. Research
            may ultimately be published in a peer-reviewed journal or commercialized either by Visby or a third party,
            but will not include your name or other information. In addition, the Visby Services, including our devices,
            may be used to support clinical trials. Information collected through our Services as part of a clinical
            trial may be used by Visby for any of the above-listed purposes, or any purpose permissible under applicable
            law.</p>
          <p><b>To Comply with Applicable Laws.</b> We may be required to process your personal information under
            certain laws and regulations, such as tax or healthcare laws. We will also process any and all information
            to law enforcement agencies or others if required to do so by law or in the good faith belief that such
            preservation or disclosure is reasonably necessary to: (a) comply with legal or regulatory process (such as
            a judicial proceeding, court order, or government inquiry) or obligations that we may owe pursuant to
            ethical and other professional rules, laws, and regulations; (b) enforce the Visby Terms of Use and other
            policies; (c) respond to claims that any content violates the rights of third parties; or (d) protect the
            rights, property, or personal safety of Visby, its employees, its users, its clients, and the public. We
            cannot provide the Services to you without such processing.</p>
          <p>We may also use your information to contact you about our own and third parties’ goods and services that
            may be of interest to you. If you do not want us to use your information in this way, please email us at <a
              href="mailto:privacy@visby.com">privacy@visby.com</a> to opt out. </p>

          <h2>4. DISCLOSURE OF PERSONAL INFORMATION</h2>
          <p>We may share or disclose your personal information in the following circumstances:</p>
          <p><b>Service Providers.</b> We may employ other companies and individuals to facilitate our Services
            (“Service Providers”), provide the Services on our behalf, perform Service-related services or assist us in
            analyzing how our Services are used. The Service Providers have access to your personal information only to
            perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. The
            Service Providers are bound by contractual obligations to keep personal information confidential and use it
            only for the purposes for which we disclose it to them. </p>
          <p><b>Within Our Corporate Organization.</b> We may share your personal information with our subsidiaries and
            affiliates in order to provide you with the Services and take actions based on your request. </p>
          <p><b>Corporate Transactions.</b> To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution or sale or transfer of some or all of our assets, whether as a
            going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information
            held by us about our Site users is among the assets transferred, and you agree to and do hereby consent to
            our assignment or transfer of rights to your personal information. </p>
          <p><b>Third Party Advertisers.</b> In certain circumstances we may share your personal information with third
            parties to market their products or services to you if you have not opted out of these disclosures. We
            contractually require these third parties to keep personal information confidential and use it only for the
            purposes for which we disclose it to them. </p>
          <p><b>Consent.</b> We may disclose your personal information for any purpose with your consent. </p>
          <p><b>As Required by Law.</b> Under certain circumstances your personal information may be subject to
            processing pursuant to laws, regulations, judicial or other government subpoenas, warrants, or orders,
            including quality regulations. We may be required to disclose personal information in coordination with
            regulatory authorities in response to lawful requests by public authorities, including to meet national
            security or law enforcement requirements, or for public health purposes, including reporting sexually
            transmitted disease results to state public health regulatory bodies. We will preserve and disclose any and
            all information to law enforcement agencies or others if required to do so by law or in the good faith
            belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal or regulatory
            process (such as a judicial proceeding, court order, or government inquiry) or obligations that we may owe
            pursuant to ethical and other professional rules, laws, and regulations; (b) enforce our Terms of Use and
            other policies; (c) respond to claims that any content violates the rights of third parties; or (d) protect
            the rights, property, or personal safety of Visby, its employees, its users, its clients, and the public.
          </p>
          <p><b>Aggregated and De-Identified Data.</b> Please note that we may disclose, without restriction, aggregated
            or de-identified information about our users, which is information that does not identify any specific
            individual. </p>

          <h2>5. KEEPING YOUR PERSONAL INFORMATION ACCURATE AND DELETION</h2>
          <p>We are committed to ensuring that your personal information is kept accurate and up to date. However, it is
            up to you to update it with any changes. You may send us an email at <a
              href="mailto:privacy@visby.com">privacy@visby.com</a> to request access to, or to correct
            any personal information that you have provided to us. We may not accommodate a request to change
            information if we believe the change would violate any law or legal requirement or cause the information to
            be incorrect. </p>
          <p>If you no longer wish to participate in our Services, or no longer wish to have your personal information
            be processed, you may request to delete your account and the information in your account, subject to certain
            limitations, by emailing us at <a href="mailto:privacy@visby.com">privacy@visby.com</a>. Once
            you submit your request, we will send an email to the email address linked to your account requesting that
            you confirm your deletion request. Once you confirm your request, this process cannot be cancelled, undone,
            withdrawn, or reversed. We may retain some information as a result of our quality and regulatory
            requirements.</p>
          <p>If you delete your User Content from the Site, copies of your User Content may remain viewable in cached
            and archived pages, or might have been copied or stored by other Site users. Proper access and use of
            information provided on the Site, including User Content, is governed by our Visitor Terms of Use. </p>

          <h2>6. CHILDREN’S PRIVACY</h2>
          <p>The Services are offered and available to users in the United States who are 18 years of age or older. We
            do not collect or maintain personal information from people we actually know are under 18 years old. If we
            obtain actual knowledge that a user is under 18 years old, we will use our best efforts to remove that
            person’s information from our database. If you are not 18 years of age or older, you must not access or use
            our Services</p>
          <h2>7. YOUR CALIFORNIA PRIVACY RIGHTS</h2>
          <p>This section applies only to California residents. Pursuant to the California Consumer Privacy Act of 2018
            (“CCPA”), below is a summary of the “Personal Information” categories, as identified and defined by the CCPA
            (see California Civil Code section 1798.140 (o)), that we collect, the reason we collect your Personal
            Information, where we obtain the Personal Information, and the third parties with whom we may share your
            Personal Information. </p>
          <p>We generally collect the following categories of Personal Information about your when you use our Site or
            services: </p>
          <ul>
            <li>identifiers such as a name, address, unique personal identifier, email, phone number, your device’s IP
              address, software, and identification numbers associated with your devices;
            </li>
            <li>protected classifications, such as gender;</li>
            <li>commercial information such as records of products or services purchased, obtained, or considered by
              you;
            </li>
            <li>Internet or other electronic information regarding you browsing history, search history, the webpage
              visited before you came to our Site, length of visit and number of page views, click-stream data, locale
              preferences, your mobile carrier, date and time stamps associated with transactions, and system
              configuration information;
            </li>
            <li>your geolocation, to the extent you have configured your device to permit us to collect such
              information
            </li>
            <li>audio recordings of your voice to the extent you call us, as permitted under applicable law;</li>
            <li>inferences about your preferences, characteristics, behavior and attitudes.</li>
          </ul>

          <p>For more information about the Personal Information we collect and how we collect it, please refer to
            Sections 1 and 2, above. We collect your Personal Information for the business purposes described in Section
            3, above. The categories of third parties with whom we may share your Personal Information are listed in
            Section 4, above. </p>

          <p><b>Privacy Rights</b></p>
          <p>If you are a California resident, you have rights in relation to your Personal Information; however, your
            rights are subject to certain exceptions. For instance, we cannot disclose specific pieces of Personal
            Information if the disclosure would create a substantial, articulable, and unreasonable risk to the security
            of the Personal Information, your account with us or the security of our network systems. </p>
          <ul>
            <li>Right Against Discrimination. You have the right not to be discriminated against for exercising any of
              the rights described in this section. We will not discriminate against you for exercising your right to
              know, delete or opt-out of sales.
            </li>
            <li>Right to Know. You have the right to request in writing: (i) a list of the categories of personal
              information, such as name, address, email address, that a business has disclosed to third parties during
              the immediately preceding calendar year for the third parties' direct marketing purposes, and (ii) the
              names and addresses of all such third parties. In addition, you have the right to request: (i) the
              categories of personal information we have collected about you, (ii) the categories of sources from which
              personal information is collected, (iii) the business or commercial purpose for the information
              collection, (iv) the categories of third parties with whom we have shared personal information, and (v)
              the specific pieces of personal information we hold about an individual. You have the right to request a
              copy of the specific Personal Information we collected about you during the 12 months before your request.
            </li>
            <li>Right to Delete. You have the right to request us delete any Personal Information we have collected from
              you or maintain about you, subject to certain exceptions.
            </li>
            <li>Right to Opt-Out. We do not generally sell information as the term “sell” is traditionally understood.
              As described in this Privacy Policy, in certain situations we may share your Personal Information with our
              partners, third parties or affiliates for our marketing or other purposes. If and to the extent “sale”
              under the CCPA is interpreted to include advertising technology activities such as those implemented
              specifically for interest-based advertising, we will comply with applicable law as to such activity.
            </li>
          </ul>
          <p>To assert your right to know or your right to delete your Personal Information, please contact according to
            the “Contact Information” section below. To verify your identity, we may ask you to verify Personal
            Information we already have on file for you. If we cannot verify your identity from the information we have
            on file, we may request additional information from you, which we will only use to verify your identity, and
            for security or fraud-prevention purposes. </p>

          <h2>8. DATA SECURITY</h2>
          <p>We have implemented measures designed to secure your personal information from accidental loss and from
            unauthorized access, use, alteration and disclosure. All personal information you provide to us is stored on
            our secure servers behind firewalls. Any payment transactions will be encrypted using SSL or other
            encryption technology, or will use our third party payment processors, who will use appropriate security
            procedures.</p>
          <p>The safety and security of your information also depends on you. Where we have given you (or where you have
            chosen) a password for access to certain parts of our Site, you are responsible for keeping this password
            confidential. You should not share your password with anyone. We urge you to be careful about giving out
            information in public areas of the Site like message boards. The information you share in public areas may
            be viewed by any user of the Site.</p>
          <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do
            our best to protect your personal information, we cannot guarantee the security of your personal information
            transmitted to our Site. Any transmission of personal information is at your own risk. We are not
            responsible for circumvention of any privacy settings or security measures contained on the Site.</p>

          <h2>9. INTEGRATION OF THIRD-PARTY PLATFORMS AND SERVICES</h2>
          <p>The Services may be linked to, rely on and be integrated with websites, applications, interfaces, services
            and platforms operated by other companies, including Third-Party Services. The Services may also feature
            advertisements from these companies. We are not responsible for the privacy practices of such websites,
            applications, interfaces, services and platforms operated by third parties that are linked to, rely on
            and/or integrated with the Services or for the privacy practices of third party advertising companies. Once
            you leave this Services via a link, access a third-party service or click on an advertisement, you should
            check applicable privacy policies to determine, among other things, how related companies process Personal
            Information they may collect about you. This Privacy Policy applies solely to information collected by
            Visby. </p>

          <h2>10. CONTACT INFORMATION</h2>
          <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: <a
            href="mailto:privacy@visby.com">privacy@visby.com</a>. </p>

          <h2>11. UPDATES</h2>
          <p>We may modify this Privacy Policy at any time, without prior notice, and changes may apply to any personal
            information we hold about you, as well as any new personal information collected after the Privacy Policy is
            modified. If we make changes, we will notify you by revising the date at the top of this Privacy Policy. We
            will provide you with advanced notice if we make any material changes to how we collect, use or disclose
            your personal information or that impact your rights under this Privacy Policy. You are responsible for
            ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting
            our Site and this Privacy Policy to check for any changes. If you continue to access or use our Services
            after receiving the notice of changes, you acknowledge your acceptance of the updated Privacy Policy. </p>
          <p>We also may provide additional "just-in-time" disclosures or additional information about the data
            collection, use and sharing practices of specific Services. Such notices may supplement or clarify our
            privacy practices or may provide you with additional choices about how we process your personal
            information. </p>
        </div>
      </Section>
    </React.Fragment>
  )
}

export default LegalPrivacy

export const Head = () => (
  <Seo
        title="Privacy Policy"
        description="Privacy Policy"
        image="/meta/legal-privacy-policy.jpg"
  />
)

