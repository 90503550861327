import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"

const StyledBacklink = styled.section`
  padding: 20px 0;
  border-bottom-width: 0;

	p {
		font-size: 15px;
		font-weight: 400;
		
		${breakpoint.small`
			font-size: 16px;
        `}
    }

  a {
      color: ${colors.orange};
      font-weight: 400;
      
     	&:hover {
          color: ${colors.purpleDark};
        }

  }
`

const Backlink = props => {
  return (
    <StyledBacklink>
      <Container>
        <p>
          <Link to="/legal/">Legal</Link> &raquo; {props.pagetitle}
        </p>
      </Container>
    </StyledBacklink>
  )
}

export default Backlink